<template>
	<v-autocomplete
		:value="name"
		:label="label"
		:items="items"
		:loading="loading"
		:search-input.sync="query"
		ref="autocomplete"
		@input="onInput"
		hide-no-data
	/>
</template>

<script>
import Api from '../../api'

export default {
	name: 'CardAutocomplete',
	props: {
		label: String,
	},
	data: function() {
		return {
			name: null,
			query: null,
			loading: false,
			items: [],
			refresh: false
		}
	},
	methods: {
		autocomplete: function() {
			this.loading = true
			if (!this.query || this.query.length < 2) {
				this.items = []
				this.loading = false
				return
			}
			Api.getCardsAutocomplete(this.query)
				.then(res => {
					this.items = res.map(name => {
						return {
							text: name,
							value: name
						}
					})
				}).finally(() => {
					if (this.refresh) {
						this.refresh = false
						this.autocomplete()
					} else this.loading = false
				})
		},
		clear: function() {
			this.name = null
		},
		focus: function() {
			this.$refs.autocomplete.focus()
		},
		onInput: function(name) {
			this.name = name
			if (name != null) {
				this.$emit('input', name)
			}
		}
	},
	watch: {
		query() {
			if (this.loading) {
				this.refresh = true
			} else {
				this.autocomplete()
			}
		}
	}
}
</script>
