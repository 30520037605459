import jwt_decode from 'jwt-decode'

export default {
	// client only
	clLogout,
	clLoggedIn,
	clUserId,

	// authentication
	postAuthLogin,

	// users
	postUsers,
	getUsersId,
	patchUsersId,

	// boxes
	postBoxes,
	getBoxes,
	getBoxesId,
	putBoxesId,
	deleteBoxesId,

	// cards (box_cards)
	postSearch,
	postCards,
	getCardsId,
	putCardsId,
	deleteCardsId,

	// cards (general cards)
	getCardsAutocomplete,
	getCardsVersions,
	getCardsSet,

	// scryfall
	scryGetSets
}

const baseUrl = '/api'

function setToken(token) {
	localStorage.tokenString = token
	localStorage.tokenData = JSON.stringify(jwt_decode(token))
}

function getTokenOrNull() {
	let tokenString = localStorage.getItem('tokenString')
	let tokenData = JSON.parse(localStorage.getItem('tokenData'))
	if (tokenString == null || tokenData == null) {
		// token doesn't exist
		return null
	}
	let halfTime = (tokenData.iat + tokenData.exp) / 2
	let now = (new Date()).getTime() / 1000
	if (now > tokenData.exp) {
		// token exists, but is expired: logout
		clLogout() // delete token
		return null
	} else if (now > halfTime) {
		// token exists but is halfway expired: refresh token but return old usable on
		refreshToken(tokenString)
		return tokenString
	} else {
		// token exists, isn't even halfway expired
		return tokenString
	}
}

function getTokenOrThrow() {
	let token = getTokenOrNull()
	if (token == null)
		throw new Error(401)
	return token
}

async function tryHandleError(res) {
	if (!res.ok) {
		let msg = await res.text()
		if (msg.length < 1)
			msg = res.statusText
		throw new Error(res.status + ": " + msg)
	}
}

async function refreshToken(token) {
	let res = await fetch(baseUrl + '/auth/refresh', {
		method: 'POST',
		headers: {
			'Authorization': 'Bearer ' + token
		}
	})
	await tryHandleError(res)
	setToken((await res.json()).token)
}

async function postAuthLogin(mail, password) {
	let res = await fetch(baseUrl + '/auth/login', {
		method: 'POST',
		body: JSON.stringify({
			mail: mail,
			password: password
		})
	})
	await tryHandleError(res)
	setToken((await res.json()).token)
}

async function postUsers(user) {
	let res = await fetch(baseUrl + '/users', {
		method: 'POST',
		body: JSON.stringify(user)
	})
	await tryHandleError(res)
}

function clLogout() {
	localStorage.removeItem('tokenString')
	localStorage.removeItem('tokenData')
}

function clLoggedIn() {
	return getTokenOrNull() != null
}

function clUserId() {
	let datastr = localStorage.getItem('tokenData')
	if (datastr != null)
		return parseInt(JSON.parse(datastr).sub)
	return -1
}

async function apiFetch(url, opt = {}) {
	let res = await fetch(baseUrl + url, Object.assign(opt, {
		headers: {
			'Authorization': 'Bearer ' + getTokenOrThrow()
		}
	}))
	await tryHandleError(res)
	return res
}

async function postSearch(query, offset, count, scope) {
	let res = await apiFetch('/search', {
		method: 'POST',
		body: JSON.stringify({
			query: query,
			offset: offset,
			count: count,
			scope: scope
		})
	})
	return await res.json()
}

async function getCardsAutocomplete(query) {
	let params = 'q=' + encodeURIComponent(query)
	let res = await apiFetch('/cards/autocomplete?' + params)
	return await res.json()
}

async function getCardsVersions(printedName) {
	let params = 'pn=' + encodeURIComponent(printedName)
	let res = await apiFetch('/cards/versions?' + params)
	return await res.json()
}

async function getCardsSet(set, lang) {
	let params = 'set=' + encodeURIComponent(set)
	params += '&lang=' + encodeURIComponent(lang)
	let res = await apiFetch('/cards/set?' + params)
	return await res.json()
}

async function postCards(card, n = 1, pcb = () => {}) {
	for (let i = 0; i < n; i++) {
		await apiFetch('/cards', {
			method: 'POST',
			body: JSON.stringify(card)
		})
		pcb()
	}
}

async function deleteCardsId(card) {
	await apiFetch('/cards/' + card.id, { method: 'DELETE' })
}

async function getCardsId(card) {
	let res = await apiFetch('/cards/' + card.id)
	return await res.json()
}

async function putCardsId(card) {
	await apiFetch('/cards/' + card.id, {
		method: 'PUT',
		body: JSON.stringify(card)
	})
}

async function getUsersId(uid) {
	let res = await apiFetch('/users/' + uid)
	return await res.json()
}

async function patchUsersId(patch) {
	await apiFetch('/users/' + clUserId(), {
		method: 'PATCH',
		body: JSON.stringify(patch)
	})
}

async function getBoxes() {
	let res = await apiFetch('/boxes')
	return await res.json()
}

async function getBoxesId(id) {
	let res = await apiFetch('/boxes/' + id)
	return await res.json()
}

async function postBoxes(box) {
	await apiFetch('/boxes', {
		method: 'POST',
		body: JSON.stringify(box)
	})
}

async function putBoxesId(bid, box) {
	await apiFetch('/boxes/' + bid, {
		method: 'PUT',
		body: JSON.stringify(box)
	})
}

async function scryGetSets() {
	let res = await fetch('https://api.scryfall.com/sets')
	await tryHandleError(res)
	return (await res.json()).data
}

async function deleteBoxesId(box_id) {
	await apiFetch('/boxes/' + box_id, { method: 'DELETE' })
}