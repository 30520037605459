<template>
	<v-container pt-6 pb-6>
		<v-row justify="center">
			<v-col cols="8">
				<v-card>
					<v-card-title>
						<h1>Add cards</h1>
					</v-card-title>
					<v-card-text>
						<v-form @submit.prevent="add">
							<v-container>
								<v-row justify="center" no-gutters>
									<v-col cols="12">
										<CardAutocomplete
											label="Name"
											ref="name"
											@input="nameSelected"
										/>
									</v-col>
									<v-col cols="12">
										<v-autocomplete
											label="Version"
											ref="version"
											v-model="version"
											:items="version_items"
											:loading="version_loading"
											return-object
										/>
									</v-col>
									<v-col
										v-if="$vuetify.breakpoint.smAndDown"
										cols="8"
									>
										<CardImage
											v-if="card != null"
											:name="card.printed_name"
											:imgurl="card.image_url"
											:finish="finish"
											:quality="quality"
										/>
									</v-col>
									<v-col cols="12">
										<v-select
											label="Finish"
											ref="finish"
											v-model="finish"
											:items="finish_items"
											:disabled="finish_items != null && finish_items.length < 2"
										/>
									</v-col>
									<v-col cols="12">
										<v-select
											label="Quality"
											ref="quality"
											v-model="quality"
											:items="quality_items"
										/>
									</v-col>
									<v-col cols="12">
										<BoxSelect
											label="Box"
											v-model="box"
										/>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Notes"
											v-model="notes"
										/>
									</v-col>
									<v-col cols="12">
										<v-alert type="error" v-if="error != null">
											{{ error }}
										</v-alert>
									</v-col>
									<v-col cols="12">
										<v-text-field
											class="mt-5"
											label="Amount"
											v-model="amount"
											@focus.native="$event.target.select()"
										/>
									</v-col>
									<v-col cols="12">
										<v-checkbox
											label="Reset form after adding"
											v-model="reset"
										/>
									</v-col>
									<v-col cols="12">
										<v-btn
											type="submit"
											large
											block
											color="success"
											class="mt-5"
											:loading="busy"
											:disabled="busy || card == null"
										>
											Add
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col
				cols="4"
				v-if="$vuetify.breakpoint.mdAndUp"
			>
				<CardImage
					v-if="card != null"
					:name="card.printed_name"
					:imgurl="card.image_url"
					:finish="finish"
					:quality="quality"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Api from '../../api'
import CardImage from '../../components/CardImage'
import CardAutocomplete from './CardAutocomplete'
import BoxSelect from '../../components/BoxSelect'

export default {
	name: 'CardsAddPage',
	components: {
		CardImage,
		CardAutocomplete,
		BoxSelect
	},
	data: function() {
		return {
			version: null,
			version_items: [],
			version_loading: false,
			card: null,
			quality: 1,
			finish: null,
			box: 0,
			notes: '',
			amount: 1,
			quality_items: [
				{ text: '🟩 near mint or excellent (1)', value: 1 },
				{ text: '🟧 good (2)', value: 2 },
				{ text: '🟫 played or worse (3)', value: 3 }
			],
			finish_items: [],
			finish_items_all: [
				{ text: '🧻 non-foil', value: 1 },
				{ text: '💎 foil', value: 2 },
				{ text: '✨ etched foil', value: 4 }
			],
			busy: false,
			error: null,
			reset: true,
		}
	},
	methods: {
		nameSelected: function(name) {
			this.version = null
			if (name != null) {
				this.version_loading = true
				Api.getCardsVersions(name)
					.then(res => {
						this.version_items = res.map(card => {
							return {
								text: card.lang.toUpperCase() + ': ' + card.printed_name + ' (' + card.set.toUpperCase() + ') [' + card.id + ']',
								value: card
							}
						})
					}).finally(() => this.version_loading = false)
			} else this.version_items = []
			if (name != null && name.length > 0)
				this.$nextTick(this.$refs.version.focus)
		},
		add: function() {
			this.busy = true
			this.error = null
			Api.postCards({
				box_id: this.box,
				card_id: this.card.id,
				quality: this.quality,
				finish: this.finish,
				notes: this.notes
			}, this.amount,
			).then(() => {
				if (this.reset) {
					this.$refs.name.clear()
					this.version = null // also sets card to null
					this.quality = 1
					this.finish = 1
					this.notes = ''
					this.amount = 1
					this.$nextTick(() => {
						this.$vuetify.goTo(0)
					})
				}
			}).catch(e => {
				this.error = e.message
			}).finally(() => {
				this.$nextTick(() => {
					this.$refs.name.focus()
				})
				this.busy = false
			})
		}
	},
	watch: {
		version() {
			if (this.version != null) {
				this.card = this.version.value
				this.finish_items = this.finish_items_all.filter(f => (this.card.finishes & f.value) > 0)
				this.finish = this.finish_items[0].value
				this.$nextTick(() => {
					this.$refs.quality.focus()
				})
			} else this.card = null
		}
	}
}
</script>
