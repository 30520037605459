<template>
	<v-app>
		<v-app-bar
			app
			:color="!$vuetify.theme.dark ? 'primary' : undefined"
			:dark="!$vuetify.theme.dark"
		>
			<router-link to="/" style="color: white; text-decoration: none;">
				<v-toolbar-title>
					<v-icon large>mdi-script-text-outline</v-icon>
					<span class="hidden-sm-and-down headline" style="vertical-align: middle;"> Tamiyo</span>
				</v-toolbar-title>
			</router-link>
			<v-tabs
				icons-and-text
				class="ml-4 mr-4"
			>
				<v-tab
					v-for="tab in visibleTabs"
					:key="tab.text"
					:to="tab.link"
				>
					{{ tab.text }}
					<v-icon>{{ tab.icon }}</v-icon>
				</v-tab>
			</v-tabs>
			<v-btn
				v-if="loggedIn"
				color="secondary"
				@click="logout"
			>
				<v-icon>mdi-logout</v-icon>
				Logout
			</v-btn>
		</v-app-bar>
		<v-main>
			<router-view @login="login" />
		</v-main>
		<v-footer dark>
			<v-col
				v-if="tuesday"
				class="text-center"
				style="background-color: red; border: 3px dashed yellow;"
				cols="12"
			>
				Today is Tuesday (<b>T</b>amiyo <b>u</b>pdate, <b>e</b>xtension and <b>s</b>ervice <b>day</b>).
				Tamiyo may not be stable and form data may be lost. Use with caution!
			</v-col>
			<v-col
				class="text-center"
				cols="12"
			>
				{{ tuesday ? 'maintained' : 'created' }} with <v-icon>mdi-heart</v-icon> by eggy
			</v-col>
		</v-footer>
	</v-app>
</template>

<script>
import Api from './api'

export default {
	name: 'App',
	data: function() {
		return {
			loggedIn: false,
			tuesday: false,
			tabs: [{
				text: 'Login',
				icon: 'mdi-account-outline',
				link: '/login',
				show: [ true, false ]
			}, {
				text: 'Register',
				icon: 'mdi-account-plus-outline',
				link: '/register',
				show: [ true, false ]
			}, {
				text: 'Boxes',
				icon: 'mdi-archive-outline',
				link: '/boxes',
				show: [ false, true ]
			}, {
				text: 'Cards',
				icon: 'mdi-cards-outline',
				link: '/cards',
				show: [ false, true ]
			}, {
				text: 'Add Card',
				icon: 'mdi-plus-box-outline',
				link: '/addcard',
				show: [ false, true ]
			}, {
				text: 'Add Set',
				icon: 'mdi-expand-all-outline',
				link: '/addset',
				show: [ false, true ]
			}, {
				text: 'Help',
				icon: 'mdi-help-circle-outline',
				link: '/help',
				show: [ true, true ]
			}, {
				text: 'Account',
				icon: 'mdi-account-cog-outline',
				link: '/account',
				show: [ false, true ]
			}]
		}
	},
	computed: {
		visibleTabs() {
			let si = this.loggedIn ? 1 : 0
			return this.tabs.filter(t => t.show[si])
		}
	},
	methods: {
		login: function() {
			this.loggedIn = true
			this.$router.push('/')
		},
		logout: function() {
			Api.clLogout()
			this.loggedIn = false
			this.$router.push('/login')
		}
	},
	created: function() {
		this.loggedIn = Api.clLoggedIn()
		let setTuesday = () => {
			this.tuesday = new Date().getDay() == 2
		}
		setTuesday()
		setInterval(setTuesday, 60 * 1000)
	}
}
</script>
