<template>
	<v-card>
		<v-card-title class="pb-0">
			# {{ value.collector_number }}
		</v-card-title>
		<v-card-text>
			<CardImage
				:imgurl="value.image_url"
				@click="leftClick"
				@contextmenu.prevent="rightClick"
			/>
			<v-row
				dense
				class="pt-2"
			>
				<v-col cols="4">
					<v-text-field
						v-model.number="value.amounts.nonfoil"
						@change="$emit('input', value)"
						:disabled="!(value.finishes & 1)"
						hide-details
						dense
					>
						<template v-slot:prepend>
							🧻
						</template>
					</v-text-field>
				</v-col>
				<v-col cols="4">
					<v-text-field
						v-model.number="value.amounts.foil"
						@change="$emit('input', value)"
						:disabled="!(value.finishes & 2)"
						hide-details
						dense
					>
						<template v-slot:prepend>
							💎
						</template>
					</v-text-field>
				</v-col>
				<v-col cols="4">
					<v-text-field
						v-model.number="value.amounts.etched"
						@change="$emit('input', value)"
						:disabled="!(value.finishes & 4)"
						hide-details
						dense
					>
						<template v-slot:prepend>
							✨
						</template>
					</v-text-field>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import CardImage from '../../components/CardImage'

export default {
	name: 'SetCard',
	components: {
		CardImage
	},
	props: {
		value: Object
	},
	methods: {
		leftClick(e) {
			if (e.ctrlKey)
				this.value.amounts.foil++
			else this.value.amounts.nonfoil++
			this.$emit('input', this.value)
		},
		rightClick(e) {
			console.log(e)
			let prop = e.ctrlKey ? 'foil' : 'nonfoil'
			if (this.value.amounts[prop] > 0) {
				this.value.amounts[prop]--
				this.$emit('input', this.value)
			}
		}
	}
}
</script>
