<template>
	<div>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<div v-on="on">
					<v-btn
						icon
						color="white"
						@click.stop="onClick"
					>
						<v-icon>
							{{ states[index].icon }}
						</v-icon>
					</v-btn>
				</div>
			</template>
			{{ states[index].tooltip }}
		</v-tooltip>
	</div>
</template>

<script>
export default {
	name: 'ScopeButton',
	data: function() {
		return {
			index: 0,
			states: [{
				scope: 'own',
				icon: 'mdi-account',
				tooltip: 'Search in my boxes'
			}, {
				scope: 'friends',
				icon: 'mdi-account-group-outline',
				tooltip: 'Search in friends boxes'
			}, {
				scope: 'all',
				icon: 'mdi-account-group',
				tooltip: 'Search in all visible boxes'
			}]
		}
	},
	methods: {
		onClick: function() {
			this.index = (this.index + 1) % 3
			this.$emit('input', this.states[this.index].scope)
		}
	}
}
</script>
