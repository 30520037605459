<template>
	<v-container>
		<v-row justify="center">
			<v-col cols="7">
				<v-card>
					<v-form
						v-model="valid"
						@submit.prevent="register"
					>
						<v-card-title>
							<h1>Register</h1>
						</v-card-title>
						<v-card-text>
							<v-text-field
								v-model="username"
								label="Username"
								:rules="[rules.required, rules.maxlen]"
								:append-icon="'mdi-account'"
								autofocus
								validate-on-blur
							/>
							<p>
								Your username must not be unique. It can only be changed by contacting support.
							</p>
							<v-text-field
								v-model="email"
								label="Email address"
								:rules="[rules.required, rules.email]"
								validate-on-blur
							/>
							<p>
								Your email address is used to login and can only be changed by contacting support. It must be unique and you should be able to receive messages under any circumstances.
								Please do not use Microsoft as your email provider, they sometimes discard emails silently instead of marking them as spam.
								Your email address will not be checked, if you don't provide your real valid address, it's your own fault.
							</p>
							<v-text-field
								v-model="password1"
								label="New Password"
								:rules="[rules.required, rules.minlen]"
								:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show ? 'text' : 'password'"
								@click:append="show = !show"
								validate-on-blur
							/>
							<v-text-field
								v-model="password2"
								label="Confirm Password"
								:rules="[rules.required, rules.pwmatch]"
								:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show ? 'text' : 'password'"
								@click:append="show = !show"
								validate-on-blur
								v-if="!show"
							/>
							<p>
								We do not enforce special password rules, please make sure your password is strong enough.
							</p>
							<v-checkbox
								v-model="privacy"
								label="I agree with the terms and conditions and the privacy policy."
								:rules="[rules.required]"
							/>
							<p>
								Your username and your cards are public information and will be visible to third parties.
								Your email address will not be shared with third parties and will only be used for communications regarding Tamiyo.
							</p>
							<div class="text-center">
								<v-btn
									type="submit"
									color="info"
									class="ma-3"
									:disabled="!valid"
								>
									Register
								</v-btn>
							</div>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Api from '../api'

export default {
	name: 'RegisterPage',
	data: function() {
		return {
			realname: '',
			username: '',
			email: '',
			password1: '',
			password2: '',
			privacy: false,

			valid: false,
			show: false,
			rules: {
				required: val => !!val || 'required',
				minlen: val => (val.length >= 8) || 'min. 8 characters',
				maxlen: val => (val.length <= 20) || 'max. 20 characters',
				email: val => val.includes('@') || 'invalid email address',
				pwmatch: () => this.show || (this.password1 == this.password2) || 'passwords do not match'
			}
		};
	},
	methods: {
		register: function() {
			Api.postUsers({
				username: this.username,
				password: this.password1,
				mail: this.email
			}).then(() => {
				alert("registered successfully, please login now")
				this.$router.push('/login')
			}).catch(e => {
				alert(e.message)
			})
		}
	}
};
</script>
