<template>
	<div>
		<v-container pt-6 pb-6>
			<AddDialog
				v-model="add_dialog"
				@save="addBox"
			/>
			<v-row
				align="center"
				class="text-center"
			>
				<v-col>
					<UserSelect
						label="User"
						includeself
						v-model="user"
					/>
				</v-col>
				<v-col>
					<v-btn
						outlined
						color="primary"
						@click="add_dialog = true"
					>
						<v-icon>mdi-plus</v-icon>
						Add Box
					</v-btn>
				</v-col>
				<v-col />
			</v-row>
			<v-row>
				<v-col
					v-for="box in filteredBoxes"
					:key="box.id"
					cols="12"
					sm="6"
					md="4"
					lg="3"
				>
					<BoxCard
						:value="box"
						@input="editBox"
						@delete="deleteBox(box.id)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="loading"
				justify="center"
			>
				<v-col cols="8" class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="70"
						width="7"
					/>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import Api from '../../api'
import BoxCard from './BoxCard'
import AddDialog from './AddDialog.vue'
import UserSelect from './UserSelect.vue'

export default {
	name: 'BoxesPage',
	components: {
		BoxCard,
		AddDialog,
		UserSelect
	},
	data: function() {
		return {
			loading: true,
			user: 0,
			boxes: [],
			add_dialog: false
		}
	},
	computed: {
		uid() {
			return Api.clUserId()
		},
		filteredBoxes() {
			return this.boxes
				.filter(b => b.user_id == this.user)
				.sort((a, b) => (a.access - b.access) || a.name.localeCompare(b.name))
		}
	},
	methods: {
		getBoxes: function() {
			this.loading = true
			Api.getBoxes()
				.then(res => this.boxes = res)
				.catch(alert)
				.finally(() => this.loading = false)
		},
		addBox: function(box) {
			Api.postBoxes(box)
				.then(() => {
					this.user = this.uid
					this.getBoxes()
				})
				.catch(alert)
		},
		editBox: function(box) {
			this.boxes = this.boxes.filter(b => b.id != box.id)
			this.boxes.push(box)
		},
		deleteBox: function(boxid) {
			Api.deleteBoxesId(boxid)
				.then(() => this.boxes = this.boxes.filter(b => b.id != boxid))
				.catch(alert)
		}
	},
	created: function() {
		this.getBoxes()
	}
}
</script>
