<template>
	<v-select
		:label="label"
		:items="filteredBoxes"
		:loading="loading"
		:disabled="disabled"
		:value="wrappedValue"
		@input="onInput"
		item-text="name"
		item-value="id"
	>
		<template v-slot:append-outer>
			<slot name="append-outer" />
		</template>
		<template v-slot:item="{ on, attrs, item }">
			<v-list-item
				v-on="on"
				v-bind="attrs"
			>
				<v-list-item-avatar style="font-size: 32px;">
					📦
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>
						{{ item.name }}
					</v-list-item-title>
					<v-list-item-subtitle>
						<span v-if="friends">
							owned by {{ item.user_id == uid ? 'you' : 'friend (#' + item.user_id + ')' }},
						</span>
						{{ access_items[item.access] }}, {{ item.count }} cards
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</template>
	</v-select>
</template>

<script>
import Api from '../api'

export default {
	name: 'BoxSelect',
	props: {
		label: String,
		friends: Boolean,
		disabled: Boolean,
		value: Number
	},
	data: function() {
		return {
			boxes: [],
			loading: true,

			access_items: [
				null,
				'private',
				'public readable',
				'public writable'
			]
		}
	},
	computed: {
		uid() {
			return Api.clUserId()
		},
		filteredBoxes() {
			// filter out public readable boxes from friends
			return this.boxes.filter(b => b.user_id == this.uid || (this.friends && b.access != 2))
		},
		wrappedValue() {
			return this.loading ? 0 : this.value
		}
	},
	methods: {
		onInput: function(val) {
			this.$emit('input', val)
		}
	},
	created: function() {
		Api.getBoxes()
			.then(res => this.boxes = res)
			.finally(() =>  this.loading = false)
	}
}
</script>
