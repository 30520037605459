<template>
	<div>
		<v-toolbar color="secondary">
			<v-container pa-0>
				<v-row
					justify="center"
					align="center"
					no-gutters
				>
					<v-col cols="6">
						<v-row align="center">
							<SearchBox
								:loading="loading"
								:value="url_query"
								:error="error"
								@input="sbInput"
							/>
							<ScopeButton
								class="ml-2"
								@input="onScopeChange"
							/>
							<v-btn
								icon
								dark
								@click="multi_editcards"
								:disabled="total < 1 || error != null || scope != 'own'"
							>
								<v-icon>mdi-circle-edit-outline</v-icon>
							</v-btn>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-toolbar>
		<v-container>
			<v-dialog
				v-if="editdialog_card != null"
				v-model="editdialog"
				width="500"
			>
				<v-card>
					<v-card-title>
						Edit card
					</v-card-title>
					<v-card-text>
						<v-select
							label="Quality"
							v-model="editdialog_card.quality"
							:items="quality_items"
						/>
						<BoxSelect
							label="Box"
							v-model="editdialog_card.box_id"
							friends
						/>
						<v-text-field
							label="Notes"
							v-model="editdialog_card.notes"
						/>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text @click="editdialog = false">
							Cancel
						</v-btn>
						<v-btn text @click="savecard" color="success">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="multi_editdialog"
				:persistent="multi_saving"
				width="500"
			>
				<v-card>
					<v-card-title>
						Edit cards
					</v-card-title>
					<v-card-text>
						<v-container pt-0 pb-0>
							<v-row>
								<v-checkbox
									v-model="multi_set_box"
									:disabled="multi_saving"
									hide-details
									class="mr-4"
								/>
								<BoxSelect
									label="Box"
									v-model="multi_box"
									:disabled="!multi_set_box || multi_saving"
									friends
								/>
							</v-row>
							<v-row>
								<v-checkbox
									v-model="multi_set_notes"
									:disabled="multi_saving"
									hide-details
									class="mr-4"
								/>
								<v-text-field
									v-model="multi_notes"
									:disabled="!multi_set_notes || multi_saving"
									label="Notes"
								/>
							</v-row>
						</v-container>
						<v-checkbox
							v-model="multi_sure"
							:disabled="multi_saving"
							:label="'I understand that this action will affect ' + total + ' cards'"
							hide-details
						/>
						<v-progress-linear
							class="mt-8 mb-4"
							v-if="multi_saving"
							:value="multi_progress"
							:indeterminate="multi_progress < 0"
							color="primary"
							height="10"
							striped
						/>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							:disabled="multi_saving"
							@click="multi_editdialog = false"
							text
						>
							Cancel
						</v-btn>
						<v-btn
							:disabled="multi_saving || (!multi_set_box && !multi_set_notes) || !multi_sure"
							@click="multi_savecards"
							color="success"
							text
						>
							Save All
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-row v-if="!loading" justify="center">
				<v-col cols="8" class="text-center">
					<v-pagination v-if="pages > 1"
						v-model="page"
						:length="pages"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					v-for="card in cards"
					:key="card.id"
					cols="4"
					sm="3"
					md="2"
					class="pa-0"
				>
					<Card
						class="mx-1 my-1"
						:card="card"
						:editable="card.user_id == uid"
						@edit="editcard(card)"
						@delete="delcard(card)"
					/>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="8" class="text-center">
					<v-progress-circular
						v-if="loading"
						color="primary"
						indeterminate
						size="70"
						width="7"
					/>
					<div v-else>
						<div v-if="cards.length > 0">
							showing cards {{ 1 + offset }} - {{ offset + cards.length }} of {{ total }}
						</div>
						<div v-else>
							<v-icon large>mdi-selection-search</v-icon>
							<div>
								no cards found
							</div>
						</div>
						<v-pagination v-if="pages > 1"
							v-model="page"
							:length="pages"
						/>
						<v-btn icon @click="search" v-if="error == null">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import Api from '../../api'
import Card from './Card'
import SearchBox from './SearchBox'
import ScopeButton from './ScopeButton'
import BoxSelect from '../../components/BoxSelect'

const cardsPerPage = 120

export default {
	name: 'CardsPage',
	components: {
		Card,
		SearchBox,
		ScopeButton,
		BoxSelect
	},
	props: {
		url_query: {
			type: String,
			default: ''
		}
	},
	data: function() {
		return {
			loading: true,
			cards: [],
			total: 0,
			page: 1,
			editdialog: false,
			editdialog_card: null,
			quality_items: [
				{ text: 'near mint or excellent', value: 1 },
				{ text: 'good', value: 2 },
				{ text: 'played or worse', value: 3 }
			],
			scope: 'own',
			multi_editdialog: false,
			multi_saving: false,
			multi_set_box: false,
			multi_box: 0,
			multi_set_notes: false,
			multi_notes: '',
			multi_sure: false,
			multi_progress: 0,
			error: null
		}
	},
	computed: {
		pages: function() {
			return Math.ceil(this.total / cardsPerPage)
		},
		offset: function() {
			return (this.page - 1) * cardsPerPage
		},
		uid() {
			return Api.clUserId()
		}
	},
	methods: {
		search: function() {
			this.loading = true
			this.error = null
			Api.postSearch(this.url_query || '', this.offset, 120, this.scope)
				.then(res => {
					this.cards = res.Cards
					this.total = res.Total
					// if the result set is empty, but total is > 0, reset page and trigger another search
					if (this.cards.length < 1 && this.total > 0) {
						// if we are (we should) on a page after the number of pages, set page to the last page
						if (this.page == this.pages - 1) {
							this.page = this.pages
						} else {
							// if not, reset page to 0 (this shouldn't really happen)
							this.page = 0
						}
					}
				})
				.catch(e => {
					this.cards = []
					this.total = 0
					this.error = e.message
				})
				.finally(() => this.loading = false)
		},
		editcard: function(card) {
			Api.getCardsId(card)
				.then(c => {
					if (!this.editdialog) {
						this.editdialog_card = c
						this.editdialog = true
					}
				}).catch(e => alert(e))
		},
		savecard: function() {
			Api.putCardsId(this.editdialog_card)
				.then(() => {
					this.editdialog = false
					this.editdialog_card = null
					this.search()
				}).catch(e => alert(e))
		},
		delcard: function(card) {
			Api.deleteCardsId(card)
				.catch(e => alert(e))
				.finally(() => this.search())
		},
		sbInput: function(query) {
			this.$router.replace('/cards?q=' + encodeURIComponent(query))
		},
		multi_editcards: function() {
			// reset all fields
			this.multi_set_box = false
			this.multi_box = 0
			this.multi_set_notes = false
			this.multi_notes = ''
			this.multi_sure = false
			// show dialog
			this.multi_editdialog = true
		},
		multi_savecards: function() {
			this.multi_saving = true
			let self = this;
			(async function() {
				// show indeterminate progress bar
				self.multi_progress = -1
				let m_tot = 1
				let m_cards = []
				while (m_cards.length < m_tot) {
					let m_res = await Api.postSearch(self.url_query, m_cards.length, 200, 'own')
					m_cards = m_cards.concat(m_res.Cards)
					m_tot = m_res.Total
				}
				if (m_cards.length != m_tot)
					throw new Error('got more cards than expected')
				// show normal progress bar
				self.multi_progress = 0
				for (let ci = 0; ci < m_tot; ci++) {
					if (self.multi_set_box)
						m_cards[ci].box_id = self.multi_box
					if (self.multi_set_notes)
						m_cards[ci].notes = self.multi_notes
					await Api.putCardsId(m_cards[ci])
					self.multi_progress = 100 * (ci + 1) / m_tot
				}
			})().catch(e => {
				alert(e)
			}).finally(() => {
				this.multi_saving = false
				this.multi_editdialog = false
				this.search()
			})
		},
		onScopeChange: function(scope) {
			this.scope = scope
			this.search()
		}
	},
	created: function() {
		this.search()
	},
	watch: {
		$route() {
			this.page = 1
			this.search()
		},
		page() {
			this.$nextTick(() => {
				this.$vuetify.goTo(0)
			})
			this.search()
		}
	}
}
</script>
