<template>
	<v-img
		style="border-radius: 4.75% / 3.5%"
		:alt="name"
		:src="imgurl"
		:aspect-ratio="61/85"
		v-on="$listeners"
	>
		<template v-slot:placeholder>
			<img
				style="width: 100%"
				src="@/assets/cardback.jpg"
			/>
		</template>
		<div
			v-if="finish > 1"
			class="fill"
			:class="'finish-' + finish"
		/>
		<div
			v-if="quality > 1"
			class="fill"
			:class="'quality-' + quality"
		/>
		<slot name="overlay" />
	</v-img>
</template>

<script>
export default {
	name: 'CardImage',
	props: {
		name: String,
		imgurl: String,
		finish: Number,
		quality: Number
	}
}
</script>

<style scoped>
div.fill {
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
}
div.finish-2 {
	background-image: linear-gradient(
		115deg,
		rgba(0, 255, 0, 0) 0%,
		rgba(0, 255, 0, 0.25) 25%,
		rgba(255, 255, 0, 0.3) 50%,
		rgba(255, 0, 0, 0.15) 75%,
		rgba(255, 0, 0, 0.3) 100%
	);
}
div.finish-4 {
	background-image: url('../assets/finish4.png');
}
div.quality-2 {
	background-image: url('../assets/quality2.png');
}
div.quality-3 {
	background-image: url('../assets/quality3.png');
}
</style>
