<template>
	<v-select
		:items="filteredUsers"
		:loading="loading"
		:disabled="disabled"
		:value="wrappedValue"
		@input="onInput"
		hide-details
		dense
	>
		<template v-slot:append-outer>
			<slot name="append-outer" />
		</template>
		<template v-slot:selection="{ item }">
			<div class="ma-1">
				<UserBadge :uid="item" />
			</div>
		</template>
		<template v-slot:item="{ on, attrs, item }">
			<v-list-item
				v-on="on"
				v-bind="attrs"
			>
				<div class="ma-1">
					<UserBadge :uid="item" />
				</div>
			</v-list-item>
		</template>
	</v-select>
</template>

<script>
import Api from '../../api'
import UserBadge from '../../components/UserBadge'

export default {
	name: 'UserSelect',
	components: {
		UserBadge
	},
	props: {
		includeself: Boolean,
		disabled: Boolean,
		value: Number
	},
	data: function() {
		return {
			user: {},
			loading: true,
		}
	},
	computed: {
		uid() {
			return Api.clUserId()
		},
		filteredUsers() {
			if (this.includeself)
				return [this.uid].concat(this.user.friends)
			return this.user.friends
		},
		wrappedValue() {
			return this.loading ? this.uid : this.value
		}
	},
	methods: {
		onInput: function(val) {
			this.$emit('input', val)
		}
	},
	created: function() {
		this.onInput(this.uid)
		Api.getUsersId(this.uid)
			.then(u => this.user = u)
			.finally(() => this.loading = false)
	}
}
</script>
