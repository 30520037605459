<template>
	<v-autocomplete
		:value="set"
		:label="label"
		:items="items"
		:loading="loading"
		@input="onInput"
		hide-no-data
	>
		<template v-slot:item="{ item }">
			<v-list-item-avatar
				color="white"
				style="justify-content: center;"
			>
				<img
					style="width: 80%; height: 80%;"
					:src="item.icon"
				/>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title v-text="item.name" />
				<v-list-item-subtitle v-text="item.value" />
			</v-list-item-content>
		</template>
	</v-autocomplete>
</template>

<script>
import Api from '../../api'

export default {
	name: 'SetAutocomplete',
	props: {
		label: String,
	},
	data: function() {
		return {
			loading: true,
			items: [],
			set: ''
		}
	},
	methods: {
		onInput: function(set) {
			this.set = set
			this.$emit('input', set)
		}
	},
	created() {
		Api.scryGetSets()
			.then(sets => this.items = sets.filter(s => !s.digitial && s.card_count > 0).map(s => {
				return {
					text: s.code.toUpperCase() + ' - ' + s.name,
					value: s.code.toUpperCase(),
					icon: s.icon_svg_uri,
					name: s.name
				}
			}))
			.catch(alert)
			.finally(() => this.loading = false)
	}
}
</script>
