import Vue from 'vue'
import App from './App'

import VueRouter from 'vue-router'
import router from './router'
Vue.use(VueRouter)

import Vuetify from 'vuetify/lib'
import { colors } from 'vuetify/lib';
const darkMode = localStorage.getItem('darkMode')
const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: colors.purple.darken4,
				secondary: colors.purple.lighten1
			},
			dark: {
				primary: colors.purple.lighten1
				// primary: colors.amber.darken1
			}
		},
		// darkMode is string(true) or darkMode is undefined
		dark: darkMode === true.toString() || !darkMode
	}
})
Vue.use(Vuetify)

Vue.config.productionTip = false

new Vue({
	render: h => h(App),
	router,
	vuetify
}).$mount('#app')
