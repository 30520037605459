<template>
	<v-container pt-6 pb-6>
		<v-row>
			<v-col
				v-for="b in blocks"
				:key="b.title"
				cols="12"
			>
				<v-card>
					<v-card-title>
						{{ b.title }}
					</v-card-title>
					<v-row>
						<v-col cols="12" md="6">
							<v-card-text v-html="b.text" />
						</v-col>
						<v-spacer />
						<v-col cols="12" md="6">
							<v-card
								class="mb-4 ml-4 mr-6 pa-2"
								:style="{ borderLeft: '4px solid ' + primaryColor }"
								v-for="e in b.examples"
								:key="e.title"
								:to="{ path: '/cards', query: { q: e.search } }"
							>
								<div>
									{{ e.title }}
								</div>
								<div>
									<code>{{ e.search }}</code>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
const P_OPERATORS = 'All comparison operators (==/=, !=, <, <=, >, >=) and the following values are supported.'
const P_TAMIYO_ONLY = '<p><i>This term is specific to Tamiyo.</i></p>'

export default {
	name: 'HelpSyntaxPage',
	data: function() {
		return {
			blocks: [
				{
					title: 'Nested searches and boolean operators',
					text: `
						<p>
							By default, all search terms are combined with AND, so each card must match all terms. It is also possible to combine them with OR by explicitely writing it as <code>term1 OR term2</code>. Subexpressions can be written by using parentheses: <code>term1 OR (term2 term3)</code>.
						</p>
					`,
					examples: [
						{
							title: 'Cards with "sol" and "ring" in their name',
							search: 'sol ring'
						}, {
							title: 'Artifacts with "mana" and "vault" or "crypt" in their name',
							search: 't:artifact (mana (vault or crypt))'
						}
					]
				}, {
					title: 'Commander',
					text: `
						<p>You can search for valid commanders with <code>is:commander</code>.</p>
						<p>This is a shorthand for <code>(t:legendary t:creature) OR o:"can be your commander"</code>.</p>
					`,
					examples: [
						{
							title: 'Commanders with white identity',
							search: 'is:commander id:w'
						}, {
							title: 'Commanders from CMR with mana value < 3',
							search: 'is:commander set:cmr mv<3'
						}
					]
				}, {
					title: 'Color and Color Identity',
					text: `
						<p>
							You can search for cards of a specific color by using <code>color:</code> or <code>c:</code> and for cards with a specific color identity with <code>identity:</code> or <code>i:</code>. By default, <code>color:</code> searches for cards including this color, and <code>identity:</code> searches for cards with no other colors.
						</p>
						<p>
							The term <code>commander:</code> is also supported but not recommended since it is only an alias for <code>identity:</code>.
						</p>
					` + P_OPERATORS + `
						<ul>
							<li>
								<a href="https://boardgames.stackexchange.com/a/11563">these</a> color combination nicknames
							</li>
							<li>single color names</li>
							<li>brown, colorless (or c)</li>
							<li>mono, monocolor(ed)</li>
							<li>multi (or m), multicolor(ed)</li>
							<li>any combination of W, U, B, R and G</li>
						</ul>
						<p></p><!-- spacing -->
					`,
					examples: [
						{
							title: 'Instants you can play with an Esper commander',
							search: 't:instant id:esper'
						}, {
							title: 'Lands with no color identity',
							search: 't:land id:c'
						}, {
							title: 'Cards that are at least blue, but never red',
							search: 'c:u -c:r'
						}
					]
				}, {
					title: 'Oracle',
					text: `
						<p>
							Oracle text can be searched for with <code>oracle:</code> or <code>o:</code>.
						</p>
						<p>
							No operators are supported.
							The search term <code>fo:</code> is also supported, but not needed since the other terms also search the full oracle text.
						</p>
					`,
					examples: [
						{
							title: 'Cards that care about planeswalkers',
							search: 'o:planeswalker'
						}, {
							title: 'Creatures with flying',
							search: 't:creature o:flying'
						}, {
							title: 'Cards that counter spells',
							search: 'o:"counter target spell"'
						}
					]
				}, {
					title: 'Type',
					text: `
						<p>
							Types can be searched with <code>type:</code> or <code>t:</code>.
						</p>
						<p>
							No operators are supported. The value must be an exact type,
							partial types (like creat instead of creature) are not supported.
						</p>
					`,
					examples: [
						{
							title: 'Lengendary creature cards',
							search: 't:legendary t:creature'
						}, {
							title: 'Artifact land cards',
							search: 't:artifact t:land'
						}
					]
				}, {
					title: 'Set',
					text: `
						<p>
							Sets can be searched with <code>set:</code>.
						</p>
						<p>
							No operators are supported and the value must be the (mostly three digit) set code.
							Set names are not supported.
						</p>
					`,
					examples: [
						{
							title: 'Cards from Double Masters',
							search: 'set:2xm'
						}
					]
				}, {
					title: 'Mana Value',
					text: `
						<p>
							You can search for cards with specific mana values (converted mana costs) by using <code>mv:</code>.
							The alternative term <code>manavalue:</code> or the old term <code>cmc:</code> can also be used.
						</p>
					` + P_OPERATORS,
					examples: [
						{
							title: 'Colorless cards that cost a lot of mana',
							search: 'c:c mv>10'
						}
					]
				}, {
					title: 'Rarity',
					text: `
						<p>
							You can search for cards with specific rarities by using <code>rarity:</code> or <code>r:</code>.
						</p>
					` + P_OPERATORS + `
						<ul>
							<li>1, 2, 3, 4 or 5</li>
							<li>c, u, r, m or s</li>
							<li>common, uncommon, rare, mythic or special</li>
							<li>mythic-rare or mythicrare</li>
						</ul>
						<p></p><!-- spacing -->
					`,
					examples: [
						{
							title: 'Cards for Pauper',
							search: 'r:c'
						}, {
							title: 'Cards that are rare or rarer',
							search: 'r>=r'
						}
					]
				}, {
					title: 'Border',
					text: `
						<p>
							You can search for card borders with <code>border:</code> and one of the following border colors:
						</p>
						<ul>
							<li>black</li>
							<li>white</li>
							<li>silver</li>
							<li>gold</li>
							<li>borderless</li>
						</ul>
						<p></p><!-- spacing -->
					`,
					examples: [
						{
							title: 'Silver-bordered cards',
							search: 'border:silver'
						}, {
							title: 'Borderless commanders',
							search: 'border:borderless is:commander'
						}
					]
				}, {
					title: 'Frame',
					text: `
						<p>
							You can search for card frames with <code>frame:</code> and one of the following frame values:
						</p>
						<ul>
							<li>1993</li>
							<li>1997</li>
							<li>old (1993 or 1997)</li>
							<li>modern or 2003</li>
							<li>new or 2015</li>
							<li>future</li>
						</ul>
						<p></p><!-- spacing -->
						<p>
							You can search for full art with <code>is:full</code>.
						</p>
					`,
					examples: [
						{
							title: 'Full-art lands',
							search: 'is:full t:land'
						}, {
							title: 'Cards in the new frame',
							search: 'frame:new'
						}, {
							title: 'Artifacts in the old frame',
							search: 'frame:old t:artifact'
						}
					]
				}, {
					title: 'Language',
					text: `
						<p>
							You can search for cards in different languages with <code>lang:</code> and one of the following language codes:
						</p>
						<ul>
							<li>EN (English)</li>
							<li>DE (German)</li>
							<li>JA (Japanese)</li>
							<li>FR (French)</li>
							<li>IT (Italian)</li>
							<li>ES (Spanish)</li>
							<li>PT (Portuguese)</li>
							<li>ZHS (Simplified Chinese)</li>
							<li>ZHT (Traditional Chinese)</li>
							<li>RU (Russian)</li>
							<li>KO (Korean)</li>
							<li>PX (Phyrexian)</li>
						</ul>
						<p></p><!-- spacing -->
						<p>
							The language codes AR, HE, GRC, LA and SA are also supported, but only used on single cards.
						</p>
					`,
					examples: [
						{
							title: 'Cards written in Phyrexian',
							search: 'lang:px'
						}
					]
				}, {
					title: 'Layout',
					text: `
						<p>
							You can search for card layouts with <code>layout:</code> and one of the following layout identifiers:
						</p>
						<ul>
							<li>adventure</li>
							<li>art_series</li>
							<li>augment</li>
							<li>class</li>
							<li>double_faced_token</li>
							<li>emblem</li>
							<li>flip</li>
							<li>host</li>
							<li>leveler</li>
							<li>meld</li>
							<li>modal_dfc</li>
							<li>normal</li>
							<li>planar</li>
							<li>reversible_card</li>
							<li>saga</li>
							<li>scheme</li>
							<li>split</li>
							<li>token</li>
							<li>transform</li>
							<li>vanguard</li>
						</ul>
						<p></p><!-- spacing -->
						<p>
							Some of these layouts can be searched with <code>is:</code> too:
						</p>
						<ul>
							<li><code>is:split</code></li>
							<li><code>is:flip</code></li>
							<li><code>is:transform</code></li>
							<li><code>is:meld</code></li>
							<li><code>is:leveler</code></li>
							<li><code>is:mdfc</code> (modal_dfc)</li>
							<li><code>is:dfc</code> (all double-faced cards)</li>
							<li><code>is:token</code> (incl. double-faced)</li>
							<li><code>is:marker</code> (tokens and emblems)</li>
						</ul>
						<p></p><!-- spacing -->
					`,
					examples: [
						{
							title: 'Cards that transform or meld',
							search: 'layout:transform or layout:meld'
						}, {
							title: 'Tokens that are not creatures',
							search: 'is:token -t:creature'
						}, {
							title: 'Art-series cards',
							search: 'layout:art_series'
						}
					]
				}, {
					title: 'Finishes',
					text: `
						<p>
							You can search for cards with a specific finish with the following terms:
						</p>
						<ul>
							<li><code>is:nonfoil</code></li>
							<li><code>is:foil</code></li>
							<li><code>is:etched</code></li>
						</ul>
						<p></p><!-- spacing -->
					`,
					examples: [
						{
							title: 'Legendary etched-foil cards',
							search: 't:legendary is:etched'
						}
					]
				}, {
					title: 'Quality',
					text: P_TAMIYO_ONLY + `
						<p>
							You can search for cards of a specific quality by using <code>quality:</code> or <code>q:</code> and values 1, 2 or 3.
						</p>
					`,
					examples: [
						{
							title: 'Cards that are near-mint or excellent',
							search: 'q:1'
						}
					]
				}, {
					title: 'Oracle Length',
					text: P_TAMIYO_ONLY + `
						<p>
							Search for the number of characters in the oracle text with <code>oraclelen:</code> or <code>ol:</code>.
						</p>
					` + P_OPERATORS,
					examples: [
						{
							title: 'Cards with more than 20 characters of oracle text',
							search: 'ol>20'
						}, {
							title: 'Vanilla creatures',
							search: 'ol=0 t:creature'
						}
					]
				}, {
					title: 'Box',
					text: P_TAMIYO_ONLY + `
						<p>
							Cards in a specific box can be searched with <code>box:</code>. The results include all cards including the value in their boxes name.
							Boxes can also be searched with <code>boxid:</code> and a comma separated list of IDs, but this feature is mostly used internally.
						</p>
						<p>
							No operators are supported.
						</p>
					`,
					examples: [
						{
							title: 'Cards in all boxes with "Deck" in their name',
							search: 'box:deck'
						}
					]
				}, {
					title: 'Notes',
					text: P_TAMIYO_ONLY + `
						<p>
							Notes text can be searched by using <code>notes:</code>. The results include all cards including the value in their notes.
						</p>
						<p>
							No operators are supported. The existence of notes can be searched with <code>has:notes</code>.
						</p>
					`,
					examples: []
				}, {
					title: 'Public',
					text: P_TAMIYO_ONLY + `
						<p>
							Cards that are public (visible for people that added you as their friend) can be searched with <code>is:public</code>.
						</p>
					`,
					examples: [
						{
							title: 'Mythic rare cards that are public',
							search: 'r:m is:public'
						}
					]
				}
			]
		}
	},
	computed: {
		primaryColor() {
			return this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].primary
		}
	}
}
</script>
