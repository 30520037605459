<template>
	<v-container
		fill-height
		pt-6 pb-6
	>
		<v-row
			justify="center"
			v-if="state === 0"
		>
			<v-col
				cols="12"
				sm="8"
				md="6"
				lg="4"
			>
				<v-card>
					<v-card-title>
						Add Set
					</v-card-title>
					<v-card-text>
						<SetAutocomplete
							label="Set"
							@input="inputSet"
						/>
						<v-select
							label="Language"
							:items="lang_items"
							v-model="lang"
						/>
						<BoxSelect
							label="Box"
							v-model="box"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							text
							@click="loadCards"
							color="success"
							:disabled="set.length < 1 || box < 1"
						>
							Start
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<template v-if="state === 1">
			<v-row>
				<v-col
					v-for="(c, i) in cs"
					:key="c.id"
					cols="4"
					sm="3"
					md="2"
				>
					<SetCard v-model="cs[i]" />
				</v-col>
			</v-row>
			<v-row
				v-if="state === 1"
				class="text-center"
				justify="center"
			>
				<v-col>
					<v-btn
						outlined
						color="primary"
						@click="saveCards"
					>
						<v-icon>mdi-plus</v-icon>
						Add {{ sum }} cards
					</v-btn>
				</v-col>
			</v-row>
		</template>
		<v-overlay :value="state === 2">
			<v-progress-circular
				:value="Math.round(100 * progress / sum)"
				size="128"
			>
				{{ progress }} / {{ sum }}
			</v-progress-circular>
		</v-overlay>
	</v-container>
</template>

<script>
import Api from '../../api'
import SetCard from './SetCard'
import SetAutocomplete from './SetAutocomplete'
import BoxSelect from '../../components/BoxSelect'

export default {
	name: 'SetAddPage',
	components: {
		SetCard,
		SetAutocomplete,
		BoxSelect
	},
	data: function() {
		return {
			lang_items: [
				{ text: '🇬🇧 English', value: 'en' },
				{ text: '🇩🇪 German', value: 'de' },
				{ text: '🇯🇵 Japanese', value: 'jp' },
				{ text: '🇫🇷 French', value: 'fr' },
				{ text: '🇮🇹 Italian', value: 'it' },
				{ text: '🇪🇸 Spanish', value: 'es' },
				{ text: '🇵🇹 Portuguese', value: 'pt' },
				{ text: '🇨🇳 Chinese simp.', value: 'zhs' },
				{ text: '🇨🇳 Chinese trad.', value: 'zht' },
				{ text: '🇷🇺 Russian', value: 'ru' },
				{ text: '🇰🇷 Korean', value: 'ko' },
				{ text: '🏴‍☠️ Phyrexian', value: 'ph' },
				{ text: '🇸🇦 Arabic', value: 'ar' },
				{ text: '🇮🇱 Hebrew', value: 'he' },
				{ text: '🇬🇷 Greek', value: 'grc' },
				{ text: '🇮🇹 Latin', value: 'la' },
				{ text: '🇮🇳 Sanskrit', value: 'sa' }
			],

			state: 0,
			set: '',
			lang: 'en',
			box: 0,

			cs: [],
			finishes: [
				{ name: 'nonfoil', value: 1 },
				{ name: 'foil', value: 2 },
				{ name: 'etched', value: 4 }
			],
			progress: 0
		}
	},
	computed: {
		sum() {
			return this.cs.map(c => c.amounts.nonfoil + c.amounts.foil + c.amounts.etched).reduce((acc, n) => acc + n, 0)
		}
	},
	methods: {
		inputSet(val) {
			this.set = val.toLowerCase()
		},
		loadCards() {
			Api.getCardsSet(this.set, this.lang)
				.then(cs => {
					this.cs = cs.filter(c => {
						return c.id
					}).sort((a, b) => {
						return a.collector_number.localeCompare(b.collector_number, undefined, { numeric: true })
					}).map(c => {
						return Object.assign(c, {
							amounts: {
								nonfoil: 0,
								foil: 0,
								etched: 0
							}
						})
					})
					this.state = 1
				}).catch(alert)
		},
		saveCards: function() {
			this.progress = 0
			this.state = 2
			let self = this;
			(async function() {
				for (const c of self.cs) {
					for (const f of self.finishes) {
						let a = c.amounts[f.name]
						if (a > 0) {
							await Api.postCards({
								box_id: self.box,
								card_id: c.id,
								quality: 1,
								finish: f.value
							}, a, () => self.progress++)
						}
					}
				}
				self.$router.push({ path: '/cards', query: { q: 'boxid:' + self.box } })
			})().catch((e) => {
				alert(e)
				this.state = 1
			})
		}
	}
}
</script>
