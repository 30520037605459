<template>
	<v-container pt-6 pb-6>
		<v-row>
			<v-col cols="4">
				<v-card>
					<v-card-title>
						Profile
					</v-card-title>
					<v-card-text>
						<UserBadge :uid="uid" />
					</v-card-text>
					<v-card-text class="pt-0">
						<v-dialog
							v-model="pw_dialog"
							width="500"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on"
									v-bind="attrs"
								>
									<v-icon>
										mdi-form-textbox-password
									</v-icon>
									Change Password
								</v-btn>
							</template>
							<v-card>
								<v-form
									v-model="pw_valid"
									@submit.prevent="changePassword"
								>
									<v-card-title>
										Change Password
									</v-card-title>
									<v-card-text>
										<v-text-field
											v-model="password1"
											label="New Password"
											:rules="[rules.required, rules.minlen]"
											:append-icon="pw_show ? 'mdi-eye' : 'mdi-eye-off'"
											:type="pw_show ? 'text' : 'password'"
											@click:append="pw_show = !pw_show"
											validate-on-blur
										/>
										<v-text-field
											v-model="password2"
											label="Confirm Password"
											:rules="[rules.required, rules.pwmatch]"
											:append-icon="pw_show ? 'mdi-eye' : 'mdi-eye-off'"
											:type="pw_show ? 'text' : 'password'"
											@click:append="pw_show = !pw_show"
											validate-on-blur
											v-if="!pw_show"
										/>
									</v-card-text>
									<v-divider />
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											text
											@click="pw_dialog = false"
										>
											Cancel
										</v-btn>
										<v-btn
											type="submit"
											text
											color="success"
											:disabled="!pw_valid"
										>
											Save
										</v-btn>
									</v-card-actions>
								</v-form>
							</v-card>
						</v-dialog>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="4">
				<v-card>
					<v-card-title>
						Client settings
					</v-card-title>
					<v-card-text>
						<v-switch
							v-model="$vuetify.theme.dark"
							label="Enable Dark Mode"
							@change="saveDarkMode"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="4">
				<v-card>
					<v-card-title>
						Friends
					</v-card-title>
					<v-card-text
						v-for="f in user.friends"
						:key="f"
						class="pt-0"
					>
						<UserBadge :uid="f" />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Api from '../../api'
import UserBadge from '../../components/UserBadge'

export default {
	name: 'AccountPage',
	components: {
		UserBadge
	},
	data: function() {
		return {
			loading: true,
			user: {},

			pw_valid: true,
			pw_dialog: false,
			pw_show: false,
			password1: '',
			password2: '',
			rules: {
				required: val => !!val || 'required',
				minlen: val => (val.length >= 8) || 'min. 8 characters',
				pwmatch: () => this.pw_show || (this.password1 == this.password2) || 'passwords do not match'
			}
		}
	},
	computed: {
		uid() {
			return Api.clUserId()
		}
	},
	methods: {
		changePassword() {
			Api.patchUsersId({
				password: this.password1
			}).then(() => {
				alert('Password changed.')
				this.pw_dialog = false
			}).catch(alert)
		},
		saveDarkMode() {
			localStorage.setItem('darkMode', this.$vuetify.theme.dark.toString())
		}
	},
	created: function() {
		Api.getUsersId(this.uid)
			.then(u => this.user = u)
			.catch(alert)
			.finally(() => this.loading = false)
	}
}
</script>
