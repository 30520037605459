<template>
	<v-dialog
		:value="value"
		@input="input"
		width="500"
	>
		<v-card>
			<v-card-title>
				Edit Box
			</v-card-title>
			<v-card-text>
				<v-text-field
					label="Name"
					v-model="edit_box.name"
				/>
				<AccessSelect
					v-model="edit_box.access"
				/>
				<v-text-field
					label="Description"
					v-model="edit_box.description"
				/>
				<v-text-field
					label="Art ID"
					v-model="edit_box.art_id"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					text
					@click="input(false)"
				>
					Cancel
				</v-btn>
				<v-btn
					text
					@click="save"
					color="success"
				>
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import AccessSelect from './AccessSelect'

export default {
	name: 'EditDialog',
	components: {
		AccessSelect
	},
	props: {
		value: Boolean,
		box: Object
	},
	data: function() {
		return {
			edit_box: {}
		}
	},
	methods: {
		input: function(val) {
			this.$emit('input', val)
		},
		save: function() {
			this.$emit('save', this.edit_box)
		}
	},
	watch: {
		value() {
			if (this.value) {
				this.edit_box = Object.assign({}, this.box)
			}
		}
	}
}
</script>
