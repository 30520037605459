<template>
	<v-dialog
		:value="value"
		@input="input"
		width="500"
	>
		<v-card>
			<v-card-title>
				Add Box
			</v-card-title>
			<v-card-text>
				<v-text-field
					label="Name"
					v-model="b_name"
				/>
				<AccessSelect
					v-model="b_access"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					text
					@click="input(false)"
				>
					Cancel
				</v-btn>
				<v-btn
					text
					@click="save"
					color="success"
				>
					Add
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import AccessSelect from './AccessSelect'

export default {
	name: 'AddDialog',
	components: {
		AccessSelect
	},
	props: {
		value: Boolean
	},
	data: function() {
		return {
			b_name: '',
			b_access: 1
		}
	},
	methods: {
		input: function(val) {
			this.$emit('input', val)
		},
		save: function() {
			this.$emit('save', {
				name: this.b_name,
				access: this.b_access
			})
			this.$emit('input', false)
			this.b_name = ''
			this.b_access = 1
		}
	}
}
</script>
