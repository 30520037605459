<template>
	<v-container fill-height>
		<v-row justify="center">
			<v-col
				cols="12"
				sm="8"
				md="6"
				lg="4"
			>
				<v-form
					v-model="valid"
					@submit.prevent="login"
				>
					<v-card>
						<v-card-title>
							Login
						</v-card-title>
						<v-card-text>
							<v-text-field
								v-model="mail"
								label="Email address"
								:append-icon="'mdi-account'"
								:rules="[rules.required, rules.email]"
								autofocus
								validate-on-blur
							/>
							<v-text-field
								v-model="password"
								label="Password"
								:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show ? 'text' : 'password'"
								@click:append="show = !show"
								:rules="[rules.required]"
							/>
							<v-alert type="error" v-if="failed">
								Authentication failed.
							</v-alert>
						</v-card-text>
						<v-card-actions>
							<v-btn to="/register">
								Register
							</v-btn>
							<v-spacer />
							<v-btn
								:disabled="!valid"
								type="submit"
								color="info"
							>
								Login
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-form>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Api from '../api'

export default {
	name: 'LoginPage',
	data: function() {
		return {
			valid: false,
			show: false,
			mail: '',
			password: '',
			failed: false,
			rules: {
				required: value => !!value || "required",
				email: val => val.includes('@') || 'invalid email address',
			}
		};
	},
	methods: {
		login: function() {
			Api.postAuthLogin(this.mail, this.password)
				.then(() => {
					this.$emit('login')
					this.mail = ''
					this.password = ''
					this.failed = false
				}).catch(() => {
					this.failed = true
				})
		}
	}
};
</script>
