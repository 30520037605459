<template>
	<v-select
		label="Access"
		:items="access_items"
		:value="value"
		@input="input"
	/>
</template>

<script>
export default {
	name: 'AccessSelect',
	props: {
		value: Number
	},
	data: function() {
		return {
			access_items: [
				{ text: '🔒 Private (not visible to anyone else)', value: 1 },
				{ text: '📢 Public readable (can be seen by friends)', value: 2 },
				{ text: '📮 Public writable (friends can also add cards)', value: 3 }
			]
		}
	},
	methods: {
		input: function(val) {
			this.$emit('input', val)
		}
	}
}
</script>
