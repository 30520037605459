import VueRouter from 'vue-router'

import BoxesPage from './pages/boxes/BoxesPage'
import CardsPage from './pages/cards/CardsPage'
import CardsAddPage from './pages/addcard/CardsAddPage'
import SetAddPage from './pages/addset/SetAddPage'
import HelpPage from './pages/help/HelpPage'
import HelpInfoPage from './pages/help/HelpInfoPage'
import HelpSyntaxPage from './pages/help/HelpSyntaxPage'
import AccountPage from './pages/account/AccountPage'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'

import Api from './api'

const routes = [{
	path: '/',
	redirect: '/cards'
}, {
	path: '/boxes',
	component: BoxesPage,
	meta: { requiresAuth: true }
}, {
	path: '/cards',
	component: CardsPage,
	meta: { requiresAuth: true },
	props: (route) => ({
		url_query: route.query.q
	})
}, {
	path: '/addcard',
	component: CardsAddPage,
	meta: { requiresAuth: true }
}, {
	path: '/addset',
	component: SetAddPage,
	meta: { requiresAuth: true }
}, {
	path: '/help',
	component: HelpPage,
	redirect: {
		path: '/help/info'
	},
	children: [{
		path: 'info',
		component: HelpInfoPage
	}, {
		path: 'syntax',
		component: HelpSyntaxPage
	}]
}, {
	path: '/account',
	component: AccountPage,
	meta: { requiresAuth: true }
}, {
	path: '/login',
	component: LoginPage
}, {
	path: '/register',
	component: RegisterPage
}]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!Api.clLoggedIn()) {
			next({ path: '/login' })
			return
		}
	}
	next()
})

export default router
