<template>
	<v-text-field
		:value="value"
		:loading="loading"
		:error-messages="error"
		@input="onInput"
		label="Search"
		hide-details
		solo
		dense
	>
		<template v-slot:append>
			<v-tooltip
				v-if="error != null"
				bottom
			>
				<template v-slot:activator="{ on }">
					<v-icon
						color="error"
						v-on="on"
					>
						mdi-alert-decagram
					</v-icon>
				</template>
				<span>{{ error }}</span>
			</v-tooltip>
		</template>
	</v-text-field>
</template>

<script>
export default {
	name: 'SearchBox',
	props: {
		loading: Boolean,
		value: String,
		error: String
	},
	data: function() {
		return {
			queue_value: null
		}
	},
	methods: {
		onInput: function(value) {
			if (this.loading)
				this.queue_value = value
			else this.$emit('input', value)
		}
	},
	watch: {
		//TODO watch value and emit update on change
		loading: function(nvalue) {
			// if finished loading
			if (!nvalue) {
				if (this.queue_value != null) {
					let qv = this.queue_value
					this.queue_value = null
					this.$emit('input', qv)
				}
			}
		}
	}
}
</script>
