<template>
	<div>
		<v-navigation-drawer
			absolute
			permanent
			expand-on-hover
		>
			<v-list-item>
				<v-list-item-icon>
					<v-icon>
						mdi-help-circle-outline
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>
						Help Topics
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider />
			<v-list dense nav>
				<v-list-item
					v-for="t in topics"
					:key="t.text"
					:to="t.link"
				>
					<v-list-item-icon>
						<v-icon>
							{{ t.icon }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							{{ t.text }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<div style="margin-left: 56px;">
			<router-view />
		</div>
	</div>
</template>

<script>
export default {
	name: 'HelpPage',
	data: function() {
		return {
			topics: [{
				icon: 'mdi-information-outline',
				text: 'General information',
				link: '/help/info'
			}, {
				icon: 'mdi-form-textbox',
				text: 'Search Syntax',
				link: '/help/syntax'
			}]
		}
	}
}
</script>
