<template>
	<div style="white-space: nowrap;">
		<v-avatar
			class="elevation-5"
			style="z-index: 1;"
			color="primary"
		>
			<v-progress-circular
				v-if="loading"
				indeterminate
				color="white"
			/>
			<img
				v-else-if="user.avatar"
				:src="user.avatar"
			/>
			<v-icon
				v-else
				dark
			>
					mdi-account-circle
			</v-icon>
		</v-avatar>
		<v-slide-x-transition origin="left">
			<v-chip
				v-if="!loading"
				class="pl-8 chip elevation-2"
				:input-value="false"
			>
				<div class="text-subtitle-1">
					{{ user.username }}
					<span style="color: gray;">
						#{{ user.id }}
					</span>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<span
								v-if="user.id == 1"
								v-on="on"
							>
								👑
							</span>
						</template>
						<span>Admin</span>
					</v-tooltip>
				</div>
			</v-chip>
		</v-slide-x-transition>
	</div>
</template>

<script>
import Api from '../api'

export default {
	name: 'UserBadge',
	props: {
		uid: Number
	},
	data: function() {
		return {
			user: null,
			loading: false,
			queue: false
		}
	},
	methods: {
		loadUser: function() {
			this.loading = true
			Api.getUsersId(this.uid)
				.then(res => this.user = res)
				.finally(() => {
					if (this.queue)
						this.loadUser()
					else this.loading = false
				})
		}
	},
	watch: {
		uid: {
			immediate: true,
			handler() {
				if (this.loading) {
					this.queue = true
				} else this.loadUser()
			}
		}
	}
}
</script>

<style scoped>
.chip {
	margin-left: -24px !important;
	user-select: none;
}
</style>
