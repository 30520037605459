import { render, staticRenderFns } from "./UserBadge.vue?vue&type=template&id=55b7c639&scoped=true&"
import script from "./UserBadge.vue?vue&type=script&lang=js&"
export * from "./UserBadge.vue?vue&type=script&lang=js&"
import style0 from "./UserBadge.vue?vue&type=style&index=0&id=55b7c639&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b7c639",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VChip,VIcon,VProgressCircular,VSlideXTransition,VTooltip})
