<template>
	<div>
		<v-dialog
			v-model="deldialog"
			width="500"
		>
			<v-card>
				<v-card-title>
					Are you sure?
				</v-card-title>
				<v-card-text>
					Really delete this card? This action cannot be undone.
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="deldialog = false">
						Cancel
					</v-btn>
					<v-btn text @click="delcard" color="error">
						Delete
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-hover v-slot:default="{ hover }">
			<CardImage
				:imgurl="card.image_url"
				:finish="card.finish"
				:quality="card.quality"
				:class="{ 'dark': $vuetify.theme.dark, 'hover-dark': hover && $vuetify.theme.dark, 'hover-light': hover && !$vuetify.theme.dark }"
			>
				<template v-slot:overlay>
					<v-fade-transition>
						<div
							v-if="hover"
							class="overlay"
						>
							<v-container>
								<v-row
									align="center"
									dense
								>
									<v-col cols="auto">
										<v-icon color="white">
											mdi-package
										</v-icon>
									</v-col>
									<v-col>
										{{ boxName }}
									</v-col>
								</v-row>
								<v-row
									v-if="card.notes"
									align="center"
									dense
								>
									<v-col cols="auto">
										<v-icon color="white">
											mdi-notebook
										</v-icon>
									</v-col>
									<v-col>
										{{ card.notes }}
									</v-col>
								</v-row>
								<v-row
									align="center"
									dense
								>
									<v-col cols="auto">
										<v-icon color="white">
											mdi-account
										</v-icon>
									</v-col>
									<v-col>
										<UserBadge
											v-if="userId > 0"
											:uid="userId"
										/>
									</v-col>
								</v-row>
								<v-row
									v-if="editable"
									align="center"
									dense
								>
									<v-col cols="auto">
										<v-icon color="white">
											mdi-cursor-default-click-outline
										</v-icon>
									</v-col>
									<v-col>
										<v-btn
											icon
											color="white"
											@click="editcard"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
										<v-btn
											icon
											color="error"
											@click="deldialog = true"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</div>
					</v-fade-transition>
				</template>
			</CardImage>
		</v-hover>
	</div>
</template>

<script>
import CardImage from '../../components/CardImage'
import UserBadge from '../../components/UserBadge.vue'
import Api from '../../api'

export default {
	name: 'Card',
	components: {
		CardImage,
		UserBadge
	},
	props: {
		card: Object
	},
	data: function() {
		return {
			deldialog: false,
			loading: false,
			refresh: false,
			boxName: '',
			userId: -1
		}
	},
	computed: {
		editable() {
			return !this.loading && this.userId == Api.clUserId() && this.userId >= 0
		}
	},
	methods: {
		loadDetails: function() {
			this.loading = true
			Api.getBoxesId(this.card.box_id)
				.then(res => {
					this.boxName = res.name
					this.userId = res.user_id
				}).finally(() => {
					if (this.refresh) {
						this.refresh = false
						this.loadDetails()
					} else this.loading = false
				})
		},
		editcard: function() {
			this.$emit('edit')
		},
		delcard: function() {
			// close confirmation dialog
			this.deldialog = false
			this.$emit('delete')
		}
	},
	watch: {
		card: {
			immediate: true,
			handler() {
				if (this.loading) {
					this.refresh = true
				} else {
					this.loadDetails()
				}
			}
		}
	}
}
</script>

<style scoped>
.dark {
	box-shadow: 0 0 8px -2px #999;
}
.hover-dark {
	box-shadow: 0 0 8px white;
}
.hover-light {
	box-shadow: 0 0 8px black;
}
div.overlay {
	position: absolute;
	width: 100%;
	min-height: 50%;
	bottom: 0;
	background: linear-gradient(
		180deg,
		rgba(0,0,0,0) 0%,
		rgba(0,0,0,0.8) 25px,
		rgba(0,0,0,0.86) 32px,
		rgba(0,0,0,0.9) 100%
	);
	color: white;
	padding-top: 24px;
	display: flex;
	align-items: center;
}
</style>
