<template>
	<v-card>
		<EditDialog
			v-model="edit_dialog"
			:box="value"
			@save="saveBox"
		/>
		<v-img
			:src="artCropUrl(value.art_id || default_art_id)"
			:lazy-src="artCropUrl(default_art_id)"
			position="center top"
			height="200px"
		>
			<template v-slot:placeholder>
				<v-row
					class="fill-height ma-0"
					justify="center"
					align="center"
				>
					<v-progress-circular
						indeterminate
						color="white"
					/>
				</v-row>
			</template>
		</v-img>
		<v-card-title>
			{{ value.name }}
			<v-spacer />
			<span class="text-no-wrap">
				<v-icon>
					mdi-cards-outline
				</v-icon>
				<span class="ml-1">
					{{ value.count }}
				</span>
			</span>
		</v-card-title>
		<v-card-subtitle>
			{{ access_items[value.access - 1] }}
		</v-card-subtitle>
		<v-card-text>
			{{ value.description }}
		</v-card-text>
		<v-card-actions>
			<v-btn
				plain
				v-if="value.count > 0"
				:disabled="value.user_id != uid"
				:to="{ path: '/cards', query: { q: 'boxid:' + value.id } }"
			>
				<v-icon>
					mdi-magnify
				</v-icon>
				Search
			</v-btn>
			<v-btn
				plain
				v-if="value.user_id == uid"
				@click="editBox"
			>
				<v-icon>
					mdi-pencil
				</v-icon>
				Edit
			</v-btn>
			<v-btn
				plain
				color="error"
				v-if="value.count < 1"
				@click="deleteBox"
			>
				<v-icon>
					mdi-delete
				</v-icon>
				Delete
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import Api from '../../api'
import EditDialog from './EditDialog'

export default {
	name: 'BoxCard',
	components: {
		EditDialog
	},
	props: {
		value: Object
	},
	data: function() {
		return {
			edit_dialog: false,
			access_items: [
				'🔒 This box is private, only you can see it.',
				'📢 This box is public readable, your friends can see it.',
				'📮 This box is public writable, your friends can put cards in it.'
			],
			default_art_id: '41ae20c0-b056-4c4f-a6d0-bbd72c37d0fc'
		}
	},
	computed: {
		uid() {
			return Api.clUserId()
		}
	},
	methods: {
		artCropUrl: function(uuid) {
			return 'https://api.scryfall.com/cards/' + uuid + '?format=image&version=art_crop'
		},
		editBox: function() {
			this.edit_dialog = true
		},
		deleteBox: function() {
			this.$emit('delete')
		},
		saveBox: function(box) {
			Api.putBoxesId(this.value.id, box)
				.then(() => {
					Api.getBoxesId(box.id)
						.then(b => this.$emit('input', b))
						.catch(alert)
					this.edit_dialog = false
				})
				.catch(alert)
		}
	}
}
</script>
