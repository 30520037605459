<template>
	<v-container pt-6 pb-6>
		<v-row>
			<v-col cols="12">
				<v-card>
					<v-card-title>
						What is Tamiyo?
					</v-card-title>
					<v-card-text>
						Tamiyo is a MTG card collection management solution. It allows you to catalog your personal collection and search for cards afterwards.
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'HelpInfoPage'
}
</script>
